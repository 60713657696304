.SolarSystem {
  height: 830px;
  position: relative;
}

.Planets {
  bottom: 0;
  display: flex;
  flex-direction: column-reverse;
  height: 750px;
  left: calc(50% - 100px);
  position: absolute;
}

.Planets:hover .radius {
  animation: stopTrasnlate;
  animation-play-state: paused;
  transform: scale(0.75);
}

@keyframes stopTrasnlate {

  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.Sun {
  background-blend-mode: difference;
  border-radius: 20%;
  mix-blend-mode: lighten;
  pointer-events: none;
  position: relative;
  top: 100px;
  user-select: none;
  width: 200px;
  z-index: 20;
}

.Sun img {
  user-select: none;
}

.Footer {
  background-color: rgba(0, 0, 0, 0.9);
  font-size: 24px;
  padding: 20px;
  width: 100%;
  z-index: 50;

}

.Footer a {
  color: white;
  text-decoration: none;
  text-shadow: 1px 1px black;
  transition: 0.3s;
}

.Footer a:hover {
  color: rgba(255, 255, 0, 0.903);
  font-size: 26px;
}

.MissionCard {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgba(255, 255, 255, 0.687);
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 220px;
  padding: 16px;
  transition: 400ms;
  width: 350px;
}

.MissionCard h3 {
  border-bottom: 1px solid white;
  font-family: Earth Orbiter Punch , sans-serif;
  padding: 10px;
}

.MissionCard:hover {
  background-color: rgba(6, 6, 6, 0.8);
  font-size: large;
  transform: scale(1.1);
  transition: 0.4s;
  transition-property: all;
}
